import { createContext, useState } from "react"

export const Context = createContext<any>(undefined)

const MyContextProvider: React.FC = ({ children }) => {
  const [taskId, setTaskId] = useState<string | null>(null)
  const [frontImgDone, setFrontImgDone] = useState(false)
  const [sideImgDone, setSideImgDone] = useState(false)
  const [frontMaskDone, setFrontMaskDone] = useState(false)
  const [sideMaskDone, setSideMaskDone] = useState(false)

  const [orientation, setOrientation] = useState({ alpha: 0, beta: 0, gamma: 0 });


  let state = {
    taskId,
    setTaskId,
    frontImgDone,
    setFrontImgDone,
    sideImgDone,
    setSideImgDone,
    frontMaskDone,
    setFrontMaskDone,
    sideMaskDone,
    setSideMaskDone,
    orientation,
    setOrientation
  }

  return (
    <Context.Provider value={state}>
      {children}
    </Context.Provider>
  )
}

export default MyContextProvider;
