import * as THREE from "three";
import React, { useEffect, useRef, useState, } from "react";
//import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { OBJLoader2, MtlObjBridge } from 'wwobjloader2';
//import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { measurements, MeasurePoint } from "./measurePoints";
import { useThree } from "@react-three/fiber";
import { Mesh, MeshPhongMaterial } from "three";
import { OrbitControls } from "@react-three/drei";
import { ObjectModel } from "../common/types";
import ConvexHullGrahamScan from "./graham";
interface MeasurePoints3D {
    name: string;
    points: THREE.Vector3[];
}

const ThreeDModel: React.FC<ObjectModel> = (objectModel) => {
    const ref = useRef<Mesh>(null!);
    const [visual, setVisual] = useState<THREE.Group>(new THREE.Group());
    const [mesh, setMesh] = useState<THREE.Mesh>(new THREE.Mesh());
    const [obj, setObj] = useState<THREE.Object3D>(new THREE.Object3D());
    const [measurePoints, setMeasurePoints] = useState<MeasurePoints3D[]>([]);
    //const [planeNormal, setPlaneNormal] = useState<THREE.Vector3>(new THREE.Vector3());

    const objLoader2 = new OBJLoader2();
    objLoader2.setUseIndices(true)
    const pointGeometry = new THREE.SphereBufferGeometry(0.01);
    const get_vertexpos = function (v: number, mesh: THREE.Mesh) {
        var vertexpos = mesh.geometry.attributes.position
        let s = vertexpos.itemSize
        let x = v * s
        let verts = vertexpos.array

        let res = new THREE.Vector3(verts[x],
            verts[x + 1], verts[x + 2])
        return res
    }
    function addScenePoint(point: THREE.Vector3, color?: number) {
        var pointMaterial = new THREE.MeshBasicMaterial({ color: color ? color : 0xfcf9a2});
        var pointM = new THREE.Mesh(pointGeometry, pointMaterial);
        pointM.position.set(point.x, point.y, point.z);
        visual.add(pointM);
    }
    let planeFromPoints = function (plane: number[], mesh: THREE.Mesh) {
        var plane_a = get_vertexpos(plane[0], mesh);
        var plane_b = get_vertexpos(plane[1], mesh);
        var plane_c = get_vertexpos(plane[2], mesh);
        let normal = new THREE.Vector3().crossVectors(
            new THREE.Vector3().subVectors(plane_a, plane_b),
            new THREE.Vector3().subVectors(plane_a, plane_c));
        normal.normalize();
        var offset = -normal.dot(plane_a);
        //offset = offset;
        const calculated = {
            normal: normal,
            offset: offset
        }
        return calculated;
    }
    const getPlaneNormal = function (mesh: THREE.Mesh) {
        let avg = new THREE.Vector3();
        var pointMap = {
            heels:
            {
                vertices: [
                    3466,
                    6867
                ]
            }
        }
        let length = pointMap.heels.vertices.length;
        const heals = pointMap.heels.vertices.map((v) => get_vertexpos(v, mesh));

        pointMap.heels.vertices.forEach(function (v) {
			avg = avg.add(get_vertexpos(v, mesh));
		});

		avg = avg.divideScalar(length)

		let head = get_vertexpos(414, mesh)
		//head.applyMatrix4(mesh.matrixWorld);
       // addScenePoint(head, 0xff0000);
        //setPlaneNormal(head.sub(avg).normalize());
        return head.sub(avg).normalize();
    }
    const getMeasurePoints = function (mp: MeasurePoint, mesh: THREE.Mesh) {
        const pts: THREE.Vector3[] = []
        if (mp.vertices != null) {
            var convexHull = null;
            var plane_origin = null;
            var tangent_x = null;
            var tangent_y = null;
            //plane=null;
            var plane = null;
            if (mp.usePlaneNormal && mp.vertices && mp.vertices.length > 2 && mp.plane == null) {
                const indis = [0, Math.round(mp.vertices.length / 3), Math.round(mp.vertices.length / 3 * 2)]
                mp.plane = [mp.vertices[indis[0]], mp.vertices[indis[1]], mp.vertices[indis[2]]]

                plane = planeFromPoints(mp.plane, mesh);
            } else if (mp.plane != null) {
                plane = planeFromPoints(mp.plane, mesh);
            }

            if (plane != null && mp.plane != null) {

                plane_origin = plane.normal.clone().multiplyScalar(-plane.offset)

                if (mp.convex_hull) {
                    let pointinPlane = get_vertexpos(mp.plane[1], mesh)
                    tangent_x = new THREE.Vector3()
                        .subVectors(pointinPlane, plane_origin)
                        .normalize();
                    tangent_y = new THREE.Vector3()
                        .crossVectors(plane.normal, tangent_x).normalize();
                    convexHull = new ConvexHullGrahamScan();
                }
            }
            var vpPrev = null;
            for (var j = 0; j < mp.vertices.length; j++) {


                var vertex = mp.vertices[j]
                //mm.vertices.push(vertex)

                var vp = get_vertexpos(vertex, mesh);
                if (convexHull != null && plane_origin != null && tangent_x != null && tangent_y) {
                    vp.sub(plane_origin);
                    let x = vp.dot(tangent_x);
                    let y = vp.dot(tangent_y);
                    convexHull.addPoint(x, y);
                } else {
                    if (plane != null && plane_origin != null) {
                        vp.sub(plane_origin).projectOnPlane(plane.normal)
                            .add(plane_origin);
                    }
                    pts.push(vp);

                }


            }
            if (convexHull != null && plane_origin != null && tangent_x != null && tangent_y != null) {
                let hull = convexHull.getHull();
                hull.push(hull[0]);
                for (var j = 0; j < hull.length; j++) {
                    let x = hull[j].x;
                    let y = hull[j].y;
                    let vp = plane_origin.clone()
                        .add(tangent_x.clone().multiplyScalar(x))
                        .add(tangent_y.clone().multiplyScalar(y));
                    //vp.applyMatrix4(model.matrixWorld);
                    //addScenePoint(vp.x, vp.y, vp.z, selectColor(i));
                    pts.push(vp);
                }
            }
        }
        return pts;
    }


    enum Direction {
        OUT = 1,
        OUT_Y = 2,
        IN_Y = 3,
        NONE
    }
    function addLine(pointA: THREE.Vector3, pointB: THREE.Vector3, visual: THREE.Group, color?: number, direction?: Direction) {
        const material = new LineMaterial({
            color: color ? color : 0xfcf9a2,
            linewidth: 0.01,
            dashed: false,
        });
        //material.color = new THREE.Color(color?color:0xfcf9a2);

        const geometry = new LineGeometry();

        var line = new Line2(geometry, material);
        var line2 = new Line2(new LineGeometry(), material);
        line2.geometry.setPositions([pointA.x, pointA.y, pointA.z, pointB.x, pointB.y, pointB.z]);
        const planeNormal = getPlaneNormal(mesh);
        let moveBy = -0.01;

        const moveDirectionA = pointA.clone().projectOnVector(planeNormal).sub(pointA).normalize().multiplyScalar(moveBy);
        const moveDirectionB = pointB.clone().projectOnVector(planeNormal).sub(pointB).normalize().multiplyScalar(moveBy);
        if (direction === Direction.OUT) {

           
            const pA = pointA.clone().add(moveDirectionA)
            const pB = pointB.clone().add(moveDirectionB);
            line.geometry.setPositions([pA.x, pA.y, pA.z, pB.x, pB.y, pB.z]);
            line2.geometry.setPositions([pointA.x, pointA.y, pointA.z, pointB.x, pointB.y, pointB.z]);
        }else if (direction === Direction.OUT_Y || direction === Direction.IN_Y) {
            moveBy = 0.015 * (direction === Direction.OUT_Y ? 1 : -1);
            /* const y_sign_a = Math.sign(pointA.y);
            const y_sign_b = Math.sign(pointB.y);
            const pA = pointA.clone().add(new THREE.Vector3(0.00, moveBy * y_sign_a, 0.00));
            const pB = pointB.clone().add(new THREE.Vector3(0.00, moveBy * y_sign_b, 0.00)); */
            const pA = pointA.clone().add(moveDirectionA.normalize().multiplyScalar(moveBy));
            const pB = pointB.clone().add(moveDirectionB.normalize().multiplyScalar(moveBy));
            line.geometry.setPositions([pA.x, pA.y, pA.z, pB.x, pB.y, pB.z]);
        }
        if (direction !== Direction.NONE) {
            line.computeLineDistances();
            visual.add(line);
        }else{
            line2.computeLineDistances();
            visual.add(line2);
        }
       
        //line2.computeLineDistances();
        //visual.add(line2);
    }

    useEffect(() => {
        console.log("use Effect load objectModel")
        const obj2 = objLoader2.load("data:model/obj;base64," + objectModel.obj, function (object: THREE.Object3D) {
            setObj(object)
            object.traverse(function (child) {
                //obj.applyMatrix4(obj.matrixWorld)
                if (child instanceof THREE.Mesh || child.type === "Mesh") {
                    const meshChild = child as THREE.Mesh
                    if (6890 === meshChild.geometry.attributes.position.count) {
                        setMesh(meshChild)
                        meshChild.material = material;
                        setMeasurePoints(measurements.map((measurePoint: MeasurePoint) => {
                            const points = getMeasurePoints(measurePoint, meshChild);
                            return { name: measurePoint.name, points: points };
                        }));
                    } else {
                        return
                    }
                }
            });

        })
    }, [])
    useEffect(() => {
        console.log("use Effect after load objectModel")
       
    }, [obj]);

    // model/obj or image/png; both works, probably others as well

    //const obj = useLoader(OBJLoader, "data:model/obj;base64," + objectModel.obj);
    const scene = new THREE.Scene();
    const axesHelper = new THREE.AxesHelper( 5 );
    //scene.add( axesHelper );



    const camera = useThree((state) => state.camera);
    camera.traverse((child) => {
        if (child instanceof THREE.PointLight) {
            camera.remove(child);
        }
    })
    scene.add(camera);

    obj.rotation.x = Math.PI;
    obj.rotation.y = Math.PI;

    let box = new THREE.Box3().setFromObject(obj);
    const size = box.getSize(new THREE.Vector3()).length();

    const material = new MeshPhongMaterial({
        color: 0xffffff,
        wireframe: false,
        flatShading: false,
        opacity: 0.2,
    });

    visual.clear();
    if (obj) {
        obj.remove(visual);
        obj.traverse((child) => {
            if (child instanceof Mesh) {
                child.material = material;
            }
        });
        obj.add(visual);
        scene.add(obj);
        //getPlaneNormal(mesh);

    }

    const light = new THREE.PointLight(0xffffff, 0.35);
    camera.add(light);

   
    //Head visual
    const visualByName = (name: string) => {
        let points = measurePoints.find((measurePoint) => measurePoint.name === name);
        if (points) {
            for (let i = 1; i < points?.points.length; i++) {
                let dir=  Direction.NONE;

                if(name==='leg_inner'){
                    dir = Direction.IN_Y
                }else if(name==='arm_outer' || name==='chest' || name==='waist' || name==='buttocks'){
                    dir = Direction.OUT
                }
                    
                addLine(points?.points[i - 1], points?.points[i], visual, 0x196e44,dir);
            }
        }
    }
    
    //end of creation of individual measurement visuals to be added to scene



    //start of adding individual measurement visuals to scene
    let measurementVisual: string = objectModel.showMeasurement;
    if (measurementVisual === "") {
        // Do nothing
    }else{
        visualByName(measurementVisual);
    }
   
    //end of adding individual measurement visuals to scene

    return (
        //returning 3D model object + scene (on canvas)
        <>
            <primitive object={scene} ref={ref} />
            <OrbitControls />
        </>
    );
};

export default ThreeDModel;
