import { IonAlert, IonButton, IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import '../theme/Home.css';
import { Context } from '../MyContext';
import ChooseCalibration from './ChooseCalibration';
import { PIPEDB_URL } from '../common/pipedb-url';
import { useAuth0 } from '@auth0/auth0-react';
import Gdpr from './Gdpr';
import { accessibilityOutline, listOutline } from 'ionicons/icons';

interface ContainerProps { }

const MainScreen: React.FC<ContainerProps> = () => {
	const { isAuthenticated } = useAuth0()

	const { taskId, setTaskId } = useContext(Context)

	const generateTask = async () => {
		let taskReq = await fetch(`${PIPEDB_URL}/task`, {
			method: 'POST',
			body: JSON.stringify({ })
		});

		let taskText = await taskReq.text();
		let task = await JSON.parse(taskText);
		setTaskId(task["ID"]);
	}

	const hostnames = ["192", "localhost"]
	return (
		<div className="container">
			<div>
				{isAuthenticated || hostnames.some(cond => window.location.hostname.includes(cond)) ? <>
					<IonButton color="success" size='large' fill="outline" 
						id="open-modal"
						onClick={() => { generateTask() }}>
						New Measurement <IonIcon slot="end"  src={accessibilityOutline}/>
					</IonButton>
					<ChooseCalibration />

					<IonButton size='large' fill="outline" routerLink="/measurements">My Measurements<IonIcon slot='end' src={listOutline}></IonIcon></IonButton>
				</> : <>
					<IonCard style={{width:'50%', left:'25%'}}>
						<IonCardContent>
							Please log in to use application!
							
						</IonCardContent>
					</IonCard>
				</>}
			</div>

		</div>
	);
};

export default MainScreen;
