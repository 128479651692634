import { IonAlert, IonContent, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { useEffect, useState } from 'react'
import { MeasurementMeta } from '../common/types';
import { trash } from 'ionicons/icons';
import Store from './StoreModel';

interface MeasurementsProps {
    enbleLink?: boolean;
}

function Measurements(props: MeasurementsProps) {
    const [photos, setPhotos] = useState<MeasurementMeta[]>([]);
    const measurements = async () => {
        const list = await Store().getList()
        if (list.length > 0) {
            setPhotos(list);
        }
    }
    useEffect(() => {
        measurements();
    }, []);
	const [ measurementToDelete, setMeasurementToDelete ] = useState<MeasurementMeta | null>(null);
    
	const removeMeasurement = async (id: string, name:string) => {
		await Store().remove(id, name);
        setPhotos(await Store().getList());
	}

    return (
        <>
            <IonContent>
				<IonList>{photos.map((photo, index) => {
					return (
                    <IonItem key={index} routerLink={props.enbleLink?`/task/${photo.generatedId}/results`:undefined}
                    >
                        <IonLabel >
                            <h1>{!photo.name ? (<></>) : (<>{photo.name}</>)} </h1>
                            <p>{photo.createdAt}</p>
                         </IonLabel>
		
							<IonIcon
								className="measurement"
                                icon={trash}
								onClick={(e) => { 
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setMeasurementToDelete(photo); } }
								size="large"
                                color="danger"
                                slot="end"
							/>
					</IonItem>);
				})}</IonList>
				<IonAlert
					header="Confirm delete"
					message={`Do you want to delete the measurement named "${measurementToDelete?.name}"?`}
					isOpen={measurementToDelete != null}
					buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						handler: () => { },
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => { removeMeasurement(measurementToDelete?.generatedId || '', measurementToDelete?.name||''); },
					},
					]}
					onDidDismiss={({ detail }) => { setMeasurementToDelete(null); }}
				></IonAlert>
			</IonContent>
        </>
    )
}

export default Measurements