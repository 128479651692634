export interface MeasurePoint {
    name: string
    vertices: number[]
    plane?: number[]
    convex_hull?: boolean
    usePlaneNormal?: boolean
    reflen?: boolean
}
export const measurements:MeasurePoint[] = [
	
	{
		name: "arm_outer",
		vertices: [
			1881,
			2208	
		],
		reflen: true
	},

	{
		name: "leg_inner",
		vertices: [
			1165,
			3412
		],
		reflen: true
	},
	{
		name: "head",
		
		vertices: [
			336,
			232,
			235,
			1,
			2,
			3,
			7,
			136,
			160,
			194,
			195,
			197,
			196,
			273,
			180,
			179,
			182,
			252,
			253,
			384,
			3765,
			3766,
			3694,
			3693,
			3692,
			3785,
			3708,
			3709,
			3707,
			3706,
			3672,
			3648,
			3518,
			3513,
			3514,
			3515,
			3745,
			3744,
			336
		],
		plane: [
			336,
			197,
			3709
		],
        usePlaneNormal: true,
	},
	
	{
		name: "neck",
		//seed_vertices: [
		//	3060,
		//	573
		//],
		vertices: [
			3060,
			573,
			570,
			1308,
			1307,
			821,
			819,
			817,
			803,
			824,
			425,
			426,
			453,
			829,
			3944,
			3921,
			3920,
			4312,
			4792,
			4793,
			4761,
			4309,
			4787,
			4788,
			4058,
			4059,
			3060
		],
		plane: [
			3060,
			803,
			4792
		],
        usePlaneNormal: true,
		convex_hull: true
	},
	{
		name: "chest",
		vertices: [
			942,
			599,
			598,
			669,
			668,
			1421,
			1424,
			612,
			610,
			1197,
			1196,
			1423,
			1422,
			2952,
			2953,
			2954,
			2955,
			2956,
			762,
			2907,
			1435,
			1436,
			1253,
			1252,
			1235,
			749,
			752,
			3015,
			4238,
			4237,
			4718,
			4735,
			4908,
			4909,
			6366,
			6365,
			6416,
			6415,
			6414,
			4227,
			4891,
			6412,
			6411,
			6327,
			6328,
			4098,
			4100,
			6490,
			6491,
			4156,
			4159,
			4086,
			4089,
			4429,
			4173,
			4172,
			4737,
			6332,
			3076,
			2870,
			1254,
			684,
			687,
			942
		],
		plane: [942, 1436, 6327],
		convex_hull: true,
        usePlaneNormal: true,
	},

	{
		name: "waist",
		vertices: [
			1336,
			4812,
			4402,
			4403,
			4405,
			4406,
			4167,
			4166,
			4193,
			4424,
			4332,
			4317,
			4316,
			4331,
			4330,
			4373,
			6389,
			6388,
			5244,
			5245,
			3021,
			1782,
			1779,
			2929,
			2928,
			886,
			845,
			844,
			831,
			830,
			846,
			939,
			705,
			678,
			679,
			855,
			919,
			916,
			917,
			1336
		],
		plane: [1336, 4331, 844],

		convex_hull: true,
		usePlaneNormal:true,
	},

	{
		name: "buttocks",
		vertices: [
			3140,
			3141,
			6561,
			6560,
			6528,
			6527,
			6526,
			6518,
			6517,
			6551,
			6550,
			4801,
			4927,
			6549,
			6554,
			6873,
			4951,
			4401,
			4759,
			4969,
			6563,
			6564,
			3146,
			3143,
			3144,
			1497,
			1276,
			913,
			1480,
			3475,
			3133,
			3128,
			1454,
			1446,
			1447,
			3130,
			3094,
			3093,
			3103,
			3105,
			3104,
			3139,
			3140
		],
		plane: [
			3140,
			6554,
			3475
		],
		usePlaneNormal: true,
		convex_hull: true
	}
]