import React, { useState, useRef, useEffect } from 'react';
import {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonInput,
    IonRow,
    IonCol,
    IonFooter,
    IonLabel,
    IonIcon,
} from '@ionic/react';
import { MeasurementResource } from "../common/types"
import heightIcon from "../assets/Icons-Images-Video/heightIcon.png";
import headIcon from "../assets/Icons-Images-Video/headIcon.png";
import neckIcon from "../assets/Icons-Images-Video/neckIcon.png";
import chestIcon from "../assets/Icons-Images-Video/chestIcon.png";
import waistIcon from "../assets/Icons-Images-Video/waistIcon.png";
import buttocksIcon from "../assets/Icons-Images-Video/buttocksIcon.png";
import innerLegIcon from "../assets/Icons-Images-Video/innerLegIcon.png";
import outerArmIcon from "../assets/Icons-Images-Video/outerArmIcon.png";
import AgreeFeedback, { GeneralFeedBack } from './AgreeFeedback';
import { PIPEDB_URL } from '../common/pipedb-url';
import { checkmarkOutline, thumbsUpOutline, closeOutline } from 'ionicons/icons';


export interface ResultsFeedBackProps {
    id: string,
    measurements: MeasurementResource
    isOpen: boolean
    setIsOpen :Function
    isFeedbackGiven: boolean
    setIsFeedbackGiven: Function
    onCloseFeedback: Function
    measurementsTrue: MeasurementResource
    setMeasurementsTrue: Function

}
const NumberInput = ({ label, getMeasurementByName, measurementsTrue, setMeasurementsTrue }: { label: string, getMeasurementByName: Function, measurementsTrue: MeasurementResource, setMeasurementsTrue: Function }) => {
    const [isTouched, setIsTouched] = useState(false);
    const [isValid, setIsValid] = useState<boolean>();
    const min = 10
    const max = 300
    const validate = (value: number) => {
        setIsValid(undefined);
        setIsValid(value!==-1 &&  value > min && value < max);
    };
    const [value, setValue] = useState(getMeasurementByName(label, measurementsTrue));
    console.log("value",value) 
    //const value = getMeasurementByName(label, measurementsTrue);
    return (<IonInput
        className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`}
        color={isValid === false ? 'danger' : undefined}
        id={label}
        aria-label="Number input"
        type="number"
        value={value === 0 ? undefined : value}
        //defaultValue={value === 0 ? undefined : value}
        
        placeholder={isValid===false?"":" (" + getMeasurementByName(label) + ")"}
        onIonChange={(ev) => {
            const number = parseInt(ev.detail.value ? ev.detail.value : '-1') as number
            validate(number);
            setValue(number);
            console.log(number)
            setMeasurementsTrue({
                ...measurementsTrue,
                [label]: number*10
            });
        }}
    >
       {isValid===false&& <IonLabel position="fixed" color={"warning"}>({min}-{max})</IonLabel>}
    </IonInput>);
}
const ResultsFeedBack = ({ id, measurements, isOpen, setIsOpen, isFeedbackGiven, setIsFeedbackGiven, onCloseFeedback, measurementsTrue,setMeasurementsTrue }: ResultsFeedBackProps) => {
    const icons = [{ icon: heightIcon, measurement: "height" }, { icon: headIcon, measurement: "head" }, { icon: neckIcon, measurement: "neck" }, { icon: chestIcon, measurement: "chest" }, { icon: outerArmIcon, measurement: "arm_outer" }, { icon: waistIcon, measurement: "waist" }, { icon: buttocksIcon, measurement: "buttocks" }, { icon: innerLegIcon, measurement: "leg_inner" }];
    
    const [generalFeedback, setGeneralFeedback] = useState<GeneralFeedBack[]>([{ key: "shape", text: "How well do you feel the shape of the avatar matches the person measured?", value: 0 }, { key: 'UX', text: 'Was it easy to follow the instructions to shoot and create an avatar for you as a photographer?', value: 0 }]);
  
    const modal = useRef<HTMLIonModalElement>(null);
    const page = useRef(undefined);
    const submit = async () => {
        let res = await fetch(`${PIPEDB_URL}/task/${id}/feedback`, {
            method: 'POST',
            body: JSON.stringify({ ...{ feedbacks: generalFeedback }, ...{ id: id } })
        });
        await fetch(`${PIPEDB_URL}/task/${id}/measurements_true`, {
            method: 'POST',
            body: JSON.stringify(measurementsTrue)
        });
        setIsFeedbackGiven(true);
        dismiss();
    }

    const [presentingElement, setPresentingElement] = useState<HTMLElement | undefined>(undefined);
    useEffect(() => {
        setPresentingElement(page.current);
    }, []);

    function dismiss() {
        onCloseFeedback();
    }
    function getMeasurementByNameMM(name: string, meas?: MeasurementResource) {
        
        if (!meas) {
            meas = measurements;
        }
        switch (name) {
            case "height":
                return meas.height;
            case "head":
                return meas.head;
            case "neck":
                return meas.neck;
            case "chest":
                return meas.chest;
            case "waist":
                return meas.waist;
            case "buttocks":
                return meas.buttocks;
            case "leg_inner":
                return meas.leg_inner;
            case "arm_outer":
                return meas.arm_outer;
            default:
                return 0;
        }
    }
    function getMeasurementByName(name: string, meas?: MeasurementResource) {
        return Math.round(getMeasurementByNameMM(name, meas) / 10);
    }

    return (
        <>
            <IonButton  fill="outline" id="open-feedback-modal" onClick={
                ()=>{
                    console.log("open feedback modal")
                    setIsOpen(true)}
                } >Feedback & Measure <IonIcon name='checkmark-outline' src={isFeedbackGiven?checkmarkOutline:thumbsUpOutline}></IonIcon></IonButton>
            <IonModal ref={modal} isOpen={isOpen} canDismiss={true} presentingElement={presentingElement}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Feedback</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => dismiss()}>
                            <IonIcon slot="end" icon={closeOutline}></IonIcon>
                                Close
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem lines='none' ><h3>General Feedback</h3> </IonItem>

                    {generalFeedback.map((feedback, index) => {
                        return (
                            <AgreeFeedback key={index} feedback={feedback} onChange={() => { }} ></AgreeFeedback>
                        );
                    })
                    }
                    <IonItem lines='none' ><h3>Enter manual measurements</h3> </IonItem>

                    <IonRow>
                        {icons.filter(icon => 'height' !== icon.measurement).map((icon, index) => {

                            return (
                                <IonCol key={index} size="6">
                                    <IonItem key={index}>
                                        <IonLabel style={{'textTransform': 'capitalize'}}>{icon.measurement.split("_")[0]}</IonLabel>
                                        <NumberInput label={icon.measurement} getMeasurementByName={getMeasurementByName} measurementsTrue={measurementsTrue} setMeasurementsTrue={setMeasurementsTrue}></NumberInput>
                                    </IonItem>
                                </IonCol>
                            );
                        })}
                    </IonRow>
                    <IonItem>

                    </IonItem>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton color="primary" expand="block" onClick={() => submit()}>Submit</IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
        </>
    )
}
export default ResultsFeedBack






