import { PluginListenerHandle } from '@capacitor/core';
import { Motion, RotationRate } from '@capacitor/motion';
import { useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Context } from '../MyContext';

const DeviceOrientation: React.FC = () => {
	const [showPermButton, setShowPermButton] = useState(false);
	const [showButtons, setShowButtons] = useState(true);
	const [orientation, setLocalOrientation] = useState<RotationRate>({ alpha: 0, beta: 0, gamma: 0 });
	const { setOrientation: setGlobalOrientation } = useContext(Context);

	let orientationHandler: PluginListenerHandle;

	useIonViewDidEnter(() => {
		if (Capacitor.isPluginAvailable('Motion')) {
			if (
				DeviceOrientationEvent !== undefined &&
				typeof (DeviceOrientationEvent as any).requestPermission === 'function'
			) {
				setShowPermButton(true);
			}
		} else {
			setShowButtons(false);
		}
	});

	const listenOrientation = async () => {
		// Request permission for iOS 13+ devices
		if (
			DeviceOrientationEvent !== undefined &&
			typeof (DeviceOrientationEvent as any).requestPermission === 'function'
		) {
			const permission = await (DeviceOrientationEvent as any).requestPermission();
			if (permission !== "granted") {
				console.log("Permission denied")
				return;
			}
			else {
				console.log("Permission granted")
			}
		}

		orientationHandler = await Motion.addListener('orientation', event => {
			//console.log('orientation', event);
			setLocalOrientation(event);
			setGlobalOrientation(event);
		});
	};

	useEffect(() => {
		listenOrientation()
	}, [])

	return (
		/*<div>
			Beta: {orientation.beta?.toFixed(1)}
		</div>*/
		null
	);
};

export default DeviceOrientation;