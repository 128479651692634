import { IonButton, IonButtons, IonHeader, IonIcon, IonImg, IonItemDivider, IonTitle, IonToolbar } from "@ionic/react"
import LoginButton from "./LoginButton"
import Gdpr from "./Gdpr"
import { useState } from "react"
import LOGO  from '../assets/logo.png'
import '../theme/Toolbar.css'
import { home } from 'ionicons/icons';

interface ContainerProps {
    subHeader?: string
    showGdpr?: boolean
}

const Toolbar = (props: ContainerProps) => {
  const [showAlert, setShowAlert] = useState(false);
    return (
<IonHeader>
<Gdpr isOpen={showAlert} ></Gdpr>
<IonToolbar>
  <IonButtons slot="primary">
  {props.showGdpr&&<IonButton onClick={() => setShowAlert(true)}>GDPR</IonButton>}


    </IonButtons>
    <IonImg src={LOGO} className="navImg"></IonImg>
    <IonButtons slot="start">
        {!props.showGdpr && <IonButton routerLink="/home" ><IonIcon src={home}></IonIcon> </IonButton>}

        {props.showGdpr&&<LoginButton></LoginButton>}
    </IonButtons>

</IonToolbar>

{props.subHeader &&
    <IonToolbar>
    <IonTitle className="subheader">{props.subHeader}</IonTitle>
  </IonToolbar>
}
</IonHeader>
    )
}
export default Toolbar;
