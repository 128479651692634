import {
	IonPage,
} from "@ionic/react";
import "../theme/Utilities.css";
import Toolbar from "../components/Toolbar";
import Measurements from "../components/Measurements";

const MeasurementsPage: React.FC = () => {
	

	return (
		<IonPage>
			<Toolbar subHeader="My Measurements"></Toolbar>
            <Measurements enbleLink={true}></Measurements>
		</IonPage>
	);
};

export default MeasurementsPage;
