import React from 'react';
import { IonCol, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRange, IonText, IonTextarea } from '@ionic/react';
import { thumbsDown, thumbsUp } from 'ionicons/icons';
import { on } from 'events';
export interface GeneralFeedBack {
    key: string
    text: string
    value: number
    updated?: boolean
    textValue?: string
}
interface AgreeFeedbackProps {
    feedback: GeneralFeedBack
    onChange: () => void
}

function AgreeFeedback(props: AgreeFeedbackProps) {
    const onIonChangeVal = (value: number) => {
        props.feedback.value = value;
        props.feedback.updated = true;
        props.onChange();
    }
    const onIonChangeText = (value: string) => {
        props.feedback.textValue = value;
        props.feedback.updated = true;
        props.onChange();
    }
    const pinFormatter = (value: number) => {
        switch (value) {
            case 1: return "Strongly disagree"
            case 2: return "Disagree"
            case 3: return "Somewhat disagree"
            case 4: return "Neutral"
            case 5: return "Somewhat agree"
            case 6: return "Agree"
            case 7: return "Strongly agree"
            default: return "Neutral"
        }
    }
    return <IonList lines="none">
        <IonItemDivider color={'light'}></IonItemDivider>
        <IonItem ><IonText> {props.feedback.text}</IonText></IonItem>
        <IonItem>
            <IonRange pin={true} pinFormatter={pinFormatter} color='primary' aria-label={props.feedback.key} ticks={true} value={props.feedback.value} snaps={true} min={1} max={7}
                onIonChange={({ detail }) => {
                    onIonChangeVal(detail.value as number)
                }}
            >
                <IonIcon slot="start" style={{ 'opacity': 0.5 }} icon={thumbsDown}>Disagree</IonIcon>
                <IonIcon slot="end" style={{ 'opacity': 0.5 }} icon={thumbsUp}>Agree</IonIcon>
            </IonRange>
        </IonItem>
        <IonItem>
            <IonTextarea placeholder="Motivation" rows={3} value={props.feedback.textValue} onIonChange={({ detail }) => onIonChangeText(detail.value as string)}>
            </IonTextarea>
        </IonItem>

    </IonList>;

}
export default AgreeFeedback;