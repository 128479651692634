import { Storage } from "@capacitor/storage"
import { MeasurementMeta, PHOTO_STORAGE, UserPhoto, } from "../common/types"
const listKey = "list"
const fullKeyPrefix = "full"
const Store = () => {
    const get = async (key: string) => {
        const value =  await Storage.get({key: fullKeyPrefix + "." + key });
        if(value.value){
            return JSON.parse(value.value) as UserPhoto;
        }
        return null;
    }
    const getList = async () => {
        const measurements = await Storage.get({ key: listKey })
        
        if (measurements.value) {
            return JSON.parse(measurements.value) as MeasurementMeta[];
        } else {
            // try to get from old storage
            const old = await Storage.get({ key: PHOTO_STORAGE });
            if (old.value) {
                const photos = JSON.parse(old.value) as UserPhoto[];
                const list = photos.map(e => {
                    return {
                        generatedId: e.generatedId,
                        name: e.name,
                        createdAt: e.createdAt
                    }
                });
                for (const photo of photos) {
                    await Storage.set({ key: fullKeyPrefix + "." + photo.generatedId, value: JSON.stringify(photo) });
                }
                
                await Storage.set({ key: listKey, value: JSON.stringify(list) });
                await Storage.remove({ key: PHOTO_STORAGE });
                console.log("old storage converted to new storage");
                return list as MeasurementMeta[];
            }
            return [];
        }
    }
    const store = async (measurement: UserPhoto) => {
        
        await Storage.set({ key: fullKeyPrefix + "." + measurement.generatedId, value: JSON.stringify(measurement) });
        const photos = await getList();
        const listItem = {
            generatedId: measurement.generatedId,
            name: measurement.name,
            createdAt: measurement.createdAt
        } as MeasurementMeta;
        const newPhotos = [listItem, ...photos] as MeasurementMeta[];
        await Storage.set({ key: listKey, value: JSON.stringify(newPhotos) });
        console.log("stored", measurement);
        return;
    }
    const remove = async (id: string, name: string) => {
        const newPhotos = await getList();
        const newPhotos2 = newPhotos.filter(e => !(e.generatedId === id && e.name === name));
        await Storage.set({ key: listKey, value: JSON.stringify(newPhotos2) });
        await Storage.remove({ key: fullKeyPrefix + "." + id });
    }
    
    return {
        get,
        store,
        getList,
        remove
    }
}
export default Store;
