
import '../theme/Webcam.css';

import { ChangeEvent, ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PIPEDB_URL } from '../common/pipedb-url';
import overlay_f from "../assets/neutral_f_average.png";
import overlay_r from "../assets/neutral_r_average.png";
import overlay_f_male from "../assets/male_f_average.png";
import overlay_r_male from "../assets/male_r_average.png";
import overlay_f_female from "../assets/female_f_average.png";
import overlay_r_female from "../assets/female_r_average.png";
import { Camera, CameraType } from 'react-camera-pro'
import { IonIcon, IonSelect, IonSelectOption } from '@ionic/react';
import DeviceOrientation from '../components/DeviceOrientation';
import { Context } from '../MyContext';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';

const overlay_image: Record<string, string> = {
  "front_female": overlay_f_female,
  "right_female": overlay_r_female,
  "front_male": overlay_f_male,
  "right_male": overlay_r_male,
  "front_neutral": overlay_f,
  "right_neutral": overlay_r,
};
interface CameraOverlayProps {
  id: string;
  pose: string;
  gender: string;
  setUploaded: Function;

};

interface imageData {
  imageSrc?: string;
  width?: number;
  height?: number;
  size?: number;
  length?: number;
}


const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  z-index: 1001;

  @media (max-aspect-ratio: 2/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const maxWidth = 768
const TakePhotoButton = styled(Button)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;
  
  @media (max-width: ${maxWidth}px) {
    &:disabled {
    background-color: rgba(128, 128, 128, 0.5);
    cursor: not-allowed;
    opacity: 0.5;
    }
  }

  &:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const UploadButton = styled(Button)`
  background: url('https://img.icons8.com/ios/50/upload-to-ftp.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const CameraOverlay = (props: CameraOverlayProps) => {
  const fileInput = useRef(null);

  const { orientation } = useContext(Context);
  const tolerance = 5;
  const [showArrows, setShowArrows] = useState(true);
  useEffect(() => {
    const isCorrectOrientation = Math.abs(orientation.beta - 90) < tolerance;
    if (isCorrectOrientation) {
      /*takePhoto();*/
      setShowArrows(false);
    } else {
      setShowArrows(true);
    }
  }, [orientation]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= maxWidth);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= maxWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Kör en gång vid montering för att sätta initialt värde
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const takePhoto = async () => {
    if (camera.current) {
      const photo = camera.current.takePhoto();
      setImage(photo);
      props.setUploaded(true);

      let response = await fetch(
        `${PIPEDB_URL}/task/${props.id}/${props.pose}_img`,
        {
          method: "POST",
          body: JSON.stringify({
            pixels: photo.split(",")[1],
            createdBy: "pro",
          }),
        }
      );
      response = await fetch(
        `${PIPEDB_URL}/task/${props.id}/${props.pose}_angle`,
        {
          method: "POST",
          body: JSON.stringify(
            {"beta": orientation.beta, "alpha": orientation.alpha, "gamma": orientation.gamma}
          ),
        }
      );
    }
  };

  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState<string | null>(null);
  const [showImage, setShowImage] = useState<boolean>(false);
  const camera = useRef<CameraType>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(undefined);
  const selectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const photo = e.target?.result?.toString();
      if (photo) {
        setImage(photo as string);
        let response = await fetch(`${PIPEDB_URL}/task/${props.id}/${props.pose}_img`, {
          method: 'POST',
          body: JSON.stringify({ pixels: photo.split(",")[1], createdBy: 'pro' })
        });
        props.setUploaded(true);

      }
    };
    fileReader.readAsDataURL(event.target.files?.[0] as Blob);

  }
  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      setDevices(videoDevices);
    })();
  }, []);
  return (
    <Wrapper>
      <div className="webcam-container"><Camera
        ref={camera}
        facingMode='environment'
        aspectRatio="cover"
        numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
        videoSourceDeviceId={activeDeviceId}
        errorMessages={{
          noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
          permissionDenied: 'Permission denied. Please refresh and give camera permission.',
          switchCamera:
            'It is not possible to switch camera to different one because there is only one video device accessible.',
          canvas: 'Canvas is not supported.',
        }}
        videoReadyCallback={() => {
          console.log('Video feed ready.');
        }}
      />
        <div className="overlay-container" style={{
          width: '100%', justifyContent: 'center', display: 'flex',
          alignItems: 'center', height: "100%", top: "0%", left: "0%"
        }}>
          <img className="overlay" src={overlay_image[props.pose+"_"+props.gender]} style={{ zIndex: 1000, maxHeight: "100%", aspectRatio:'preserve', justifyContent: 'center', opacity: '50%' }} />
        </div>
        <div className='motionInfo'>
				{<DeviceOrientation />}
        {showArrows && (
          orientation.beta > 90 ? (
            <IonIcon icon={caretDownOutline} className='icon-size down' />
          ) : (
            <IonIcon icon={caretUpOutline} className='icon-size up' />
          )
        )}
				</div>
      </div>

      <Control>

        <ChangeFacingCameraButton
          /* disabled={numberOfCameras <= 1} */
          onClick={() => {
            document.getElementById("selectCamera")?.click();
          }}
        />
        <IonSelect hidden placeholder="Select camera" title='Select camera' id="selectCamera"

          onIonChange={(details) => {
            console.log("event.currentTarget.value", details.detail.value);
            setActiveDeviceId(details.detail.value);
          }}  >
          {devices.map((d) => (
            <IonSelectOption key={d.deviceId} value={d.deviceId}>
              {d.label}
            </IonSelectOption>))
          }
        </IonSelect>
        <div>
          <input
            ref={fileInput}
            id='fileInput.upload'
            hidden
            type="file"
            accept="image/jpeg"
            onChange={selectFile}
            onClick={() => { console.log('onClick'); }}
          />
          <UploadButton onClick={() => document.getElementById('fileInput.upload')?.click()}></UploadButton>
          <TakePhotoButton
            disabled={isMobile && !(Math.abs(orientation.beta - 90) <= tolerance)}
            onClick={async () => {
              takePhoto();
            }}
          />
        </div>
      </Control>
    </Wrapper>
  );
};

export default CameraOverlay;