import { useContext, useEffect, useState } from 'react';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Capacitor, CapacitorException } from '@capacitor/core';
import { useHistory } from 'react-router';
import { Context } from '../MyContext';
import { delay } from '../common/delay';
import { PIPEDB_URL } from '../common/pipedb-url';

import overlay_f from "../assets/neutral_f_average.png";
import overlay_r from "../assets/neutral_r_average.png";

import { PHOTO_STORAGE, UserPhoto } from '../common/types';

const overlay_image: Record<string, string> = {
	"f": overlay_f,
	"r": overlay_r,
};

const padding = "5%";

export function usePhotoGallery() {
	const { setFrontImgDone, setSideImgDone, setFrontMaskDone, setSideMaskDone } = useContext(Context)
	const [photos, setPhotos] = useState<UserPhoto[]>([]);

	const [exif, setExif] = useState<any>({});

	const history = useHistory();

	const savePicture = async (photo: Photo, fileName: string, generatedId: string, which: string, createdAt: string): Promise<UserPhoto> => {
		if (which == "front_img") {
			setFrontImgDone(false);
			setFrontMaskDone(false);
			history.push(`/task/${generatedId}/front_mask`);
		}
		else if (which == "right_img") {
			setSideImgDone(false);
			setSideMaskDone(false);
			history.push(`/task/${generatedId}/right_mask`);
		}
		if (photos.length === 30) {
			photos.pop()
		}

		const base64Data = photo.base64String?photo.base64String: (await base64FromPath(photo.webPath!)).split(",")[1];
		const base64File = base64Data;// await base64Data.slice(22)
		//const base64File = base64Data.split(",")[1]
		const savedFile = await Filesystem.writeFile({
			path: fileName,
			data: base64Data,
			directory: Directory.Data,
		});

		let response = await fetch(`${PIPEDB_URL}/task/${generatedId}/${which}`, {
			method: 'POST',
			body: JSON.stringify({ pixels: base64File, createdBy: 'app-alpha1' })
		});
		console.log(generatedId, which, await response.json())

		if (which == "front_img") {
			setFrontImgDone(true);
		}
		else if (which == "right_img") {
			setSideImgDone(true);
		}

		// Use webPath to display the new image instead of base64 since it's
		// already loaded into memory
		return {
			exif: photo.exif,
			path: photo.path,
			generatedId: generatedId,
			name: "",
			createdAt: createdAt,
			objectModel: "",
			measurements: {
				id: '',
				createdAt: '',
				height: 0,
				head: 0,
				neck: 0,
				chest: 0,
				waist: 0,
				buttocks: 0,
				leg_inner: 0,
				arm_outer: 0
			}
		};
	};

	useEffect(() => {
		const loadSaved = async () => {
			const { value } = await Storage.get({ key: PHOTO_STORAGE });
			const photosInStorage = (value ? JSON.parse(value) : []) as UserPhoto[];

			setPhotos(photosInStorage);
		};
		loadSaved();
	}, []);

	const takePhoto = async (generatedId: string, which: string) => {
		var observer2: (MutationObserver | null) = null; // Will be observer that moves around the overlay on React updates
		var observer = whenChildNodeAdded(document.getElementsByTagName('body')[0], 'pwa-camera-modal-instance', async (node) => {
			observer.disconnect();
			// Find the camera element through all shadow roots
			var element: Element = node as Element;
			var shadowRoot: ShadowRoot = element.shadowRoot as ShadowRoot;
			element = await waitForChildNodeAdded(shadowRoot) as Element;
			element = element.querySelector('pwa-camera') as Element;
			//shadowRoot = element.shadowRoot as ShadowRoot;
			//element = await waitForChildNodeAdded(shadowRoot) as Element;

			//var el = shadowRoot.querySelector(".camera-video");
			var el = await waitForChildNodeAdded(shadowRoot) as Element;
			el = shadowRoot.querySelector("div") as Element;

			//console.log("EL:", el)

			//if(el)
			//  el.style.height="95%";
			const image = document.createElement("img");
			image.src = overlay_image[which[0]];
			image.style.position = "absolute";
			image.style.left = "0";
			image.style.right = "0";
			image.style.top = "0";
			image.style.bottom = "0";
			image.style.opacity = "0.5"
			image.style.width = "100%";
			image.style.height = "100%";
			image.style.boxSizing = "border-box";
			image.style.paddingLeft = padding;
			image.style.paddingRight = padding;
			image.style.objectFit = "contain";
			//image.style.filter = "url(" + overlay_filter + "#filter)"
			//console.log(image.style.getPropertyValue("filter"))
			const overlay = document.createElement("div");
			overlay.style.position = "absolute";
			overlay.style.maxWidth = "100%";
			overlay.style.maxHeight = "100%";
			overlay.style.margin = "auto";
			overlay.style.inset = padding + " 0 " + padding + " 0";
			overlay.appendChild(image);

			const moveOverlay = (dest: Element) => {
				//console.log(overlay.querySelector("img")?.style.getPropertyValue("filter"))
				dest.appendChild(overlay);
			}
			const camdiv = el as (Element | null);
			var shElement = document.body.querySelector("pwa-camera-modal-instance")?.shadowRoot?.querySelector("pwa-camera") as Element;
			var shRoot = shElement.shadowRoot as ShadowRoot;
			await delay(500)
			if (camdiv !== null) {
				moveOverlay(shRoot.querySelector(".camera-video") as Element);
			}
			// Sometimes the camdiv is moved around or added later, so also look for changes.
			const moveIfCameraVideo = (element: Element) => {
				if (element.className === "accept") {
					var style = document.createElement('style')
					style.innerHTML = '.accept {overflow: hidden; position: relative; width: 100%;}'
					element.appendChild(style)
				}
				if (element.className === 'camera-video') {
					moveOverlay(element);
				}
			}
			observer2 = new MutationObserver((mutationList, observer) => {
				for (const mutation of mutationList) {
					if (mutation.type === 'childList') {
						for (const addedNode of Array.from(mutation.addedNodes)) {
							moveIfCameraVideo(addedNode as Element);
						}
					} else if (mutation.type === 'attributes') {
						moveIfCameraVideo(mutation.target as Element);
					}
				}
			});
			observer2.observe(shRoot, {
				subtree: true, childList: true, attributeFilter: ['class'],
			});
		});
		try {
			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				source: CameraSource.Camera,
				correctOrientation: true,
				quality: 100,
				width: 3000,
			});
			setExif(photo.exif);
			console.log("PHOTO.EX:", photo.exif)
			console.log("len ", photo.base64String?.length)
			if(photo.path)
			{
				console.log("PHOTO PATH:", photo.path)
				console.log("PHOTO PATH:", photo.webPath)
				
				const photoInTempStorage = await Filesystem.readFile({ path: photo.path });
				
			}
			//data:image/png;base64,

			const createdAt = new Date().toLocaleString();
			const fileName = new Date().getTime() + '.jpeg';
			const savedFileImage = await savePicture(photo, fileName, generatedId, which, createdAt);
			
			const newPhotos = [savedFileImage, ...photos];
			//setPhotos(newPhotos);
			//Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });
		} catch (e) {
			if (e instanceof CapacitorException && e.message === 'User cancelled photos app') {
				// Ignore (prints a lot in console otherwise)
			} else {
				throw e; // Rethrow
			}
		} finally {
			observer.disconnect();
			if (observer2 !== null) {
				(observer2 as MutationObserver).disconnect();
			}
		}
	};

	return {
		photos,
		exif,
		setPhotos,
		takePhoto,
		savePicture,
	};
}

export async function base64FromPath(path: string): Promise<string> {
	const response = await fetch(path);
	const blob = await response.blob();
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			if (typeof reader.result === 'string') {
				resolve(reader.result);
			} else {
				reject('method did not return a string');
			}
		};
		reader.readAsDataURL(blob);
	});
}

function whenChildNodeAdded(parent: Node, tagname: string, callback: (node: Node, observer: MutationObserver) => any) {
	tagname = tagname.toLowerCase();
	const observer = new MutationObserver((mutationList, observer) => {
		for (const mutation of mutationList) {
			if (mutation.type === 'childList') {
				for (const addedNode of Array.from(mutation.addedNodes)) {
					if (addedNode.nodeName.toLowerCase() === tagname) {
						callback(addedNode, observer);
					}
				}
			}
		}
	});
	observer.observe(parent, {
		subtree: false, childList: true,
	});
	return observer;
}

function waitForChildNodeAdded(parent: Node): Promise<Node> {
	return new Promise(resolve => {
		const observer = new MutationObserver((mutationList, observer) => {
			for (const mutation of mutationList) {
				if (mutation.type === 'childList') {
					for (const addedNode of Array.from(mutation.addedNodes)) {
						observer.disconnect();
						resolve(addedNode);
						return;
					}
				}
			}
		});

		observer.observe(parent, {
			subtree: false, childList: true,
		});

		// To avoid race condition (might have added before we called observe?)
		if (parent.firstChild !== null) {
			return resolve(parent.firstChild);
		}
	});
}

