import { IonRow, IonCol, IonCard, IonList, IonItem, IonLabel, IonInput } from "@ionic/react";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import ThreeDModel from "./3DModel";
import { MeasurementResource, ObjectModel } from "../common/types";
import heightIcon from "../assets/Icons-Images-Video/heightIcon.png";
import headIcon from "../assets/Icons-Images-Video/headIcon.png";
import neckIcon from "../assets/Icons-Images-Video/neckIcon.png";
import chestIcon from "../assets/Icons-Images-Video/chestIcon.png";
import waistIcon from "../assets/Icons-Images-Video/waistIcon.png";
import buttocksIcon from "../assets/Icons-Images-Video/buttocksIcon.png";
import innerLegIcon from "../assets/Icons-Images-Video/innerLegIcon.png";
import outerArmIcon from "../assets/Icons-Images-Video/outerArmIcon.png";
interface MeasurmentResultProps {
    objectModel: ObjectModel;
    setObjectModel: Function;
    measurements: MeasurementResource;
}
const MeasurmentResult = (props: MeasurmentResultProps) => {
    const icons = [{ icon: heightIcon, measurement: "height" }, { icon: headIcon, measurement: "head" }, { icon: neckIcon, measurement: "neck" }, { icon: chestIcon, measurement: "chest" }, { icon: outerArmIcon, measurement: "arm_outer" }, { icon: waistIcon, measurement: "waist" }, { icon: buttocksIcon, measurement: "buttocks" }, { icon: innerLegIcon, measurement: "leg_inner" }];
    const { objectModel, setObjectModel, measurements } = props;
    function showHideVisualEffect(name: string) {
        if (objectModel.showMeasurement === name) {
            setObjectModel({
                ...objectModel,
                ...{ showMeasurement: '' },
            });
        } else {
            setObjectModel({
                ...objectModel,
                ...{ showMeasurement: name },
            });
        }
    }
    const getMeasurementByName = (name: string, meas?:MeasurementResource) => {
        if (!meas) {
            meas = measurements;
        }else{
            console.log("meas",meas)
        }
        switch (name) {
            case "height":
                return Math.round(meas.height / 10);
            case "head":
                return Math.round(meas.head / 10);
            case "neck":
                return Math.round(meas.neck / 10);
            case "chest":
                return Math.round(meas.chest / 10);
            case "arm_outer":
                return Math.round(meas.arm_outer / 10);
            case "waist":
                return Math.round(meas.waist / 10);
            case "buttocks":
                return Math.round(meas.buttocks / 10);
            case "leg_inner":
                return Math.round(meas.leg_inner / 10);
            default:
                return 0;
        }
    }

    return (

        <IonRow>
            <IonCol size="12">
                <IonCard className="resultPhoto" color="medium">
                    <Canvas
                        camera={{
                            fov: 45,
                            aspect: window.innerWidth / window.innerHeight,
                            zoom: 1.3,
                            near: 1,
                            far: 1100,
                        }}>
                        <color attach="background" args={["white"]} />
                        <Suspense fallback={null}>
                            <ThreeDModel
                                id={objectModel.id}
                                createdAt={objectModel.createdAt}
                                obj={objectModel.obj}
                                visibleEffects={objectModel.visibleEffects}
                                showMeasurement={objectModel.showMeasurement}
                                measurements={objectModel.measurements}
                            />
                        </Suspense>
                    </Canvas>
                </IonCard>
            </IonCol>
            <div className="overlay">


            <IonCol size="5">
                <IonList className="measureList">
                    {icons.map((icon, index) => {
                        return (
                            //<IonRow >
                            <IonItem key={index}

                                //fill={(objectModel.showMeasurement===icon.measurement)?'outline':'clear' }
                                color="0xff38a9"
                                className={(objectModel.showMeasurement === icon.measurement) ? "selectedMeasurement measurementBtn" : "measurementBtn"}
                                onClick={() => showHideVisualEffect(icon.measurement)}>
                                <IonLabel>
                                    {icon.measurement.split('_')[0].toUpperCase()}
                                    <br />
                                    
                                    {Math.round(getMeasurementByName(icon.measurement) )
                                    } cm</IonLabel>

                            </IonItem>
                            //   </IonRow>
                        )
                    }
                    )}
                </IonList>
            </IonCol>
            </div>
        </IonRow>

    );
}

export default MeasurmentResult;