import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonPage, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import MainScreen from '../components/MainScreen';
import '../theme/Home.css';
import gitcommit from '../assets/gitcommit.json'
import "../theme/Utilities.css";
import HomePageBckgrVideo from '../assets/Icons-Images-Video/HomePageBckgrVideo.mp4';
import Toolbar from '../components/Toolbar';
import { useAuth0 } from '@auth0/auth0-react';


const title = `Sizewall - commit ${gitcommit[0]}`;

const Home: React.FC = () => {
  const {isAuthenticated} = useAuth0()

  return (
    <IonPage>
      <Toolbar subHeader='Home' showGdpr={true} ></Toolbar>
      <IonContent fullscreen>
{/*         <h1 className="logoText">SIZEWALL</h1>
 */}        <div className="BckgrVideo">
          {" "}
          <video
            loop={true}
            playsInline={true}
            muted={true}
            autoPlay={true}
            id="HomePageBckgrVideo">
            <source src={HomePageBckgrVideo} type="video/mp4" />
          </video>
        </div>

        <MainScreen />
      </IonContent>
    </IonPage>
  );
};

export default Home;
