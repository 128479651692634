import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import React, { useRef, useState } from 'react'
import { checkmarkOutline, saveOutline } from 'ionicons/icons';


function NameModal(props: { saveModel: Function,isSaved:boolean }) {
	const modal = useRef<HTMLIonModalElement>(null);
	const input = useRef<HTMLIonInputElement>(null);

	const [message, setMessage] = useState(
		'This modal example uses triggers to automatically open a modal when the button is clicked.'
	);

	function confirm() {
		props.saveModel(input.current?.value)
		modal.current?.dismiss(input.current?.value, 'confirm');
	}

	function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
		if (ev.detail.role === 'confirm') {
			setMessage(`Hello, ${ev.detail.data}!`);
		}
	}

	return (
		<>
			<IonButton fill="outline"  color="success" id="open-name-modal" expand='block'>
				Save <IonIcon slot="end" src={props.isSaved?checkmarkOutline:saveOutline}></IonIcon>
			</IonButton>
			<IonModal ref={modal} trigger="open-name-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
						</IonButtons>
						<IonTitle>Name the measurement</IonTitle>
						<IonButtons slot="end">
							<IonButton strong={true} onClick={() => confirm()}>
								Confirm
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<IonItem>
						<IonLabel position="stacked">Enter your name</IonLabel>
						<IonInput ref={input} type="text" placeholder="Your name" />
					</IonItem>
				</IonContent>
			</IonModal>
		</>
	)
}

export default NameModal