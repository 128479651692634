import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {cameraOutline,} from "ionicons/icons";
import image from "../assets/Instructions/FrontPoseInstructions.png";
import "../theme/Instructions.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";
import Toolbar from "../components/Toolbar";
const FrontPosePage: React.FC = () => {

    const { id, gender } = useParams<{ id: string, gender: string }>()
    return (
        <IonPage>

            <Toolbar subHeader="Front Pose"></Toolbar>
            <IonContent>
                <div className="image">
                    <img src={image} alt="" />
                </div>

                <div className="buttonsUnderInstructions">
                    <IonButton size="large" fill="outline"
                        routerLink={`/photo/${id}/${gender}/front`}
                    >
                        Open camera <IonIcon icon={cameraOutline} />
                    </IonButton>
                </div>


            </IonContent>
        </IonPage>
    );
};

export default FrontPosePage;
