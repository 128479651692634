import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {
    homeOutline,
    informationCircleOutline,
} from "ionicons/icons";
import image from "../assets/Instructions/SidePoseInstructions.png";
import "../theme/Instructions.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";
import config from "../config";
import Toolbar from "../components/Toolbar";
const FrontPosePage: React.FC = () => {
    const { id, gender } = useParams<{ id: string, gender: string }>()

    return (
        <IonPage>
            {config.useToolbar ? <Toolbar></Toolbar> :
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonTitle className="infoBtn">
                                <IonButton routerLink="/InstructionsOnSwipe">
                                    <IonIcon slot="icon-only" icon={informationCircleOutline} />
                                </IonButton>
                            </IonTitle>
                        </IonButtons>

                        <IonButtons slot="end">
                            <IonButton routerLink="/home" class="homeBtn">
                                <IonIcon slot="icon-only" icon={homeOutline}></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonToolbar>
                        <IonTitle className="titleToolbar">Side Pose</IonTitle>
                    </IonToolbar>
                </IonHeader>
            }

            <IonContent>
                <div className="image">
                    <img src={image} alt="" />
                </div>

                <div className="buttonsUnderInstructions">
                    <IonButton size="large" fill="outline" 
                        routerLink={`/photo/${id}/${gender}/right`}>
                        Open camera
                    </IonButton>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default FrontPosePage;
